const channel = [
  {
    title: 'Google My Business',
    slug: 'google-my-business',
    status: true,
    parent: 'integrations',
    icon: 'chat',
    code: 'google_my_business',
    value: 'google_my_business',
    label: 'Google My Business',
  },
  {
    title: 'Mobile chat',
    slug: 'mobilechat',
    status: true,
    parent: 'integrations',
    icon: 'chat',
    code: 'app_chat',
    value: 'app_chat',
    label: 'Mobile chat',
  },
  {
    title: 'Tokopedia',
    slug: 'tokopedia',
    status: true,
    parent: 'integrations',
    icon: 'Tokopedia',
    code: 'tokopedia_chat',
    value: 'tokopedia_chat',
    label: 'Tokopedia',
  },
  {
    title: 'Shopee',
    slug: 'shopee',
    status: true,
    parent: 'integrations',
    icon: 'Shopee',
    code: 'shopee',
    value: 'shopee',
    label: 'Shopee',
  },
  {
    title: 'Web chat',
    slug: 'webchat',
    status: true,
    parent: 'integrations',
    icon: 'chat',
    code: 'web_chat',
    value: 'web_chat',
    label: 'Web chat',
  },
  {
    title: 'WhatsApp',
    slug: 'whatsapp',
    status: true,
    parent: 'integrations',
    icon: 'WhatsApp',
    code: 'wa_cloud',
    value: 'wa_cloud',
    label: 'WhatsApp',
  },
  {
    title: 'Facebook Messenger',
    slug: 'facebook_messenger',
    status: true,
    parent: 'integrations',
    icon: 'chat',
    code: 'fb',
    value: 'fb',
    label: 'Facebook Messenger',
  },
  {
    title: 'Instagram',
    slug: 'instagram',
    status: true,
    parent: 'integrations',
    icon: 'chat',
    code: 'ig',
    value: 'ig',
    label: 'Instagram',
  },
  {
    title: 'Email',
    slug: 'email',
    status: true,
    parent: 'integrations',
    icon: 'envelope',
    code: 'email',
    value: 'email',
    label: 'Email',
  },
  {
    title: 'Telegram',
    slug: 'telegram',
    status: true,
    parent: 'integrations',
    icon: 'chat',
    code: 'telegram',
    value: 'telegram',
    label: 'Telegram',
  },
  {
    title: 'X (Twitter)',
    slug: 'twitter',
    status: true,
    parent: 'integrations',
    icon: 'chat',
    code: 'twitter',
    value: 'twitter',
    label: 'X (Twitter)',
  },
  {
    title: 'Line Messenger',
    slug: 'line_messenger',
    status: true,
    parent: 'integrations',
    icon: 'chat',
    code: 'line',
    value: 'line',
    label: 'Line Messenger',
  },
  {
    title: 'Live Chat',
    slug: 'livechatcom',
    status: true,
    parent: 'integrations',
    icon: 'chat',
    code: 'livechat_dot_com',
    value: 'livechat_dot_com',
    label: 'Live Chat',
  },
  {
    title: 'Qontak CRM',
    slug: 'qontak_crm',
    status: true,
    parent: 'integrations',
    icon: 'share',
    code: 'qontak',
    value: 'qontak',
    label: 'Qontak CRM',
  },
  {
    title: 'Qiscus Multichannel',
    slug: 'qiscus',
    status: false,
    parent: 'integrations',
    icon: 'share',
    code: 'qiscus',
    value: 'qiscus',
    label: 'Qiscus Multichannel',
  },
]
export default channel
